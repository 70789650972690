<template>
  <div class="text-center">
    <v-dialog
        v-model="dialogShow"
        width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ infoDialogTitle }}
        </v-card-title>

        <v-card-text>
          {{ infoDialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialogShow = false"
          >
            Oke and Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
export default {
  name: "CloseDialog",
  data() {
    return {
      dialogShow: false,

    }
  },

  props: {
    infoDialogTitle: String,
    infoDialogMessage: String,
  },
  computed: {
  },
  methods: {
    showDialog() {
      // console.log("from parent " + message)
      this.dialogShow = !this.dialogShow
    },
    setDialogState(value){
      console.log(value)
      this.dialogShow =false
    }


  }

}
</script>

<style scoped>

</style>