<template>
<div>
  <h1 class="ml-4">Ubah Profile Pengguna</h1>
  <FChangeProfile></FChangeProfile>
</div>
</template>

<script>
import FChangeProfile from "../../components/setting/FChangeProfile";
export default {
  name: "ChangeProfile",
  components: {FChangeProfile}
}
</script>

<style scoped>

</style>